
.App {
  /* text-align: center; */
  /* background-color: black; */
  color: white;
  font-size:16px;
  display:flex;
  align-items: center;
  flex-direction: column;
}

a {
  color: white;
}
